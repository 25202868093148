import React from "react";

const Tokenomics = () => {
  const tokenomicsData = [
    { name: "Token Name", value: "BitcoinETF" },
    { name: "SYMBOL", value: "BTCETF" },
    { name: "Airdrop", value: "10%" },
    { name: "Liquidity", value: "15%" },
    { name: "Marketing & Promotion", value: "7%" },
    { name: "CEX Listing", value: "13%" },
    { name: "Launchpad", value: "10%" },
    { name: "Team", value: "15%" },
    { name: "Partners", value: "10%" },
    { name: "Staking Reward", value: "20%" },
  ];

  return (
    <div className="overflow-x-auto mb-10 mt-20" id="tokenomics">
      <h1 className="font-bold text-5xl text-white mb-4 md:mb-8">Tokenomics</h1>
      <div className="flex flex-col">
        <div className="flex justify-between items-center bg-gray-800 border-b border-white py-2 px-4">
          <span className="text-sm font-bold text-white">Name</span>
          <span className="text-sm font-bold text-white">Value</span>
        </div>
        {tokenomicsData.map((row, index) => (
          <div
            key={index}
            className="flex justify-between items-center border-b border-l border-r border-white py-2 px-4">
            <span className="text-sm font-thin mr-12 text-white">
              {row.name}
            </span>
            <span className="text-lg font-bold text-white  md:ml-40">
              {row.value}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tokenomics;
