import React from "react";
import img1 from "../Assets/img1.jpg";
import img2 from "../Assets/img2.jpg";
import img3 from "../Assets/img3.jpg";
import img4 from "../Assets/img4.jpg.jpg";
import img5 from "../Assets/img5.webp";
import img6 from "../Assets/img6.jpg";
import img7 from "../Assets/img7.avif";
import img8 from "../Assets/img8.jpg";
import img9 from "../Assets/img9.jpg";
import img10 from "../Assets/img10.jpeg";
import img11 from "../Assets/img11.jpg";
import img12 from "../Assets/img12.webp";

const News = () => {
  const newsData = [
    {
      id: 1,
      title: "Bitcoin ETF Will Be Approved Before Jan-10th 2024",
      imageUrl: img1,
      websiteUrl:
        "https://cointelegraph.com/news/bitcoin-etf-decision-unlikely-to-delay-due-to-sec-x-hack-say-observers",
    },
    {
      id: 2,
      title: " Back SEC probes cybersecurity breach amid Bitcoin ETF",
      imageUrl: img2,
      websiteUrl:
        "https://www.livemint.com/news/world/sec-probes-cybersecurity-breach-amid-bitcoin-etf-approval-rumours-11704852478062.html",
    },
    {
      id: 3,
      title: "Crypto Boosters Attack SEC for 'Manipulating' BTC Market After",
      imageUrl: img3,
      websiteUrl:
        "https://www.coindesk.com/policy/2024/01/10/crypto-boosters-attack-sec-for-manipulating-btc-market-after-etf-tweet/?utm_medium=referral&utm_source=rss&utm_campaign=headlines",
    },
    {
      id: 4,
      title:
        "Bitcoin ETF: Cryptocurrency swings as watchdog X account 'compromised'",
      imageUrl: img4,
      websiteUrl: "https://www.bbc.com/news/business-67931172",
    },
    {
      id: 5,
      title:
        "Crypto Degens Are Betting Millions on a Bitcoin ETF Being Approved",
      imageUrl: img5,
      websiteUrl:
        "https://decrypt.co/212220/crypto-degens-betting-millions-bitcoin-etf-being-approved",
    },
    {
      id: 6,
      title: "Bitcoin ETF Will Be Approved Before Jan-10th 2024: Says CEO",
      imageUrl: img6,
      websiteUrl:
        "https://coinpedia.org/news/bitcoin-etf-will-be-approved-before-jan-10th-2024-says-ceo-of-galaxy-digital/",
    },
    {
      id: 7,
      title: "Spot Bitcoin ETF biggest Wall St development in 30 years",
      imageUrl: img7,
      websiteUrl:
        "https://cointelegraph.com/news/bitcoin-spot-etf-biggest-wall-street-development-30-years-michael-saylor",
    },
    {
      id: 8,
      title: "Spot Bitcoin ETFs Poised to Tap $30 Trillion Market",
      imageUrl: img8,
      websiteUrl:
        "https://news.bitcoin.com/spot-bitcoin-etfs-poised-to-tap-30-trillion-market-says-grayscale-ceo/",
    },
    {
      id: 9,
      title: "News Explorer — BlackRock Adjusts Bitcoin ETF Filing Ahead",
      imageUrl: img9,
      websiteUrl:
        "https://decrypt.co/news-explorer?pinned=416819&title=blackrock-adjusts-bitcoin-etf-filing-ahead-of-potential-launch",
    },
    {
      id: 10,
      title: "Crypto Analyst: Bitcoin Could Hit $1 Million in Days to Weeks",
      imageUrl: img10,
      websiteUrl:
        "https://cryptonews.com/news/crypto-analyst-bitcoin-could-hit-1-million-in-days-to-weeks-after-spot-etf-approval.htm",
    },
    {
      id: 11,
      title: "Bitcoin Bull Market to Continue Heading Into Christmas? (BTC",
      imageUrl: img11,
      websiteUrl:
        "https://cryptopotato.com/bitcoin-bull-market-to-continue-heading-into-christmas-btc-price-analysis/",
    },
    {
      id: 12,
      title: "How Bitcoin ETF Token Gives Investors Better Exposure to the",
      imageUrl: img12,
      websiteUrl:
        "https://www.newsbtc.com/news/company/how-bitcoin-etf-token-gives-investors-better-exposure-to-the-bull-market-than-bitcoin/",
    },
  ];
  return (
    <div className="mb-20">
      <h1 className="text-3xl font-bold text-white text-left mb-4">
        Latest Bitcoin ETF News
      </h1>
      <div className="flex overflow-x-auto ">
        {/* flex justify-evenly flex-wrap */}
        {newsData.map((news) => (
          <div
            key={news.id}
            className="w-80 min-w-72 m-4 p-4 border rounded shadow-md flex flex-col justify-between">
            <img
              src={news.imageUrl}
              alt={news.title}
              className="w-full h-44 rounded object-cover"
            />
            <div className="mt-4">
              <h3 className="text-lg text-white font-bold mb-12">
                {news.title}
              </h3>
              <a
                href={news.websiteUrl}
                target="_blank"
                rel="noopener noreferrer"
                className=" text-yellow-400 font-base">
                Read more
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default News;
