import React, { useState } from "react";
import { TbCopy } from "react-icons/tb";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import NavBar from "./components/NavBar";
import Roadmap from "./components/Roadmap";
import HowToBuy from "./components/HowToBuy";
import News from "./components/News";
import { BsTwitterX } from "react-icons/bs";
import { FaTelegramPlane } from "react-icons/fa";
import WhatIsToken from "./components/WhatIsToken";
import Tokenomics from "./components/Tokenomics";
import FAQ from "./components/FAQ";
import logo from './Assets/logo.png'
function App() {
  // eslint-disable-next-line
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyToClipboard = () => {
    const address = "0x2399EC8c5E34f83F24c43f8b0a02616fF0ad5525";

    if (navigator.clipboard) {
      navigator.clipboard.writeText(address)
        .then(() => {
          setIsCopied(true);

          // Reset the copied status after a short delay
          setTimeout(() => {
            setIsCopied(false);
          }, 1500);

          // Show toast notification
          toast.success("Copied to clipboard!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 1500,
          });
        })
        .catch((err) => {
          console.error("Unable to copy to clipboard", err);
          // Handle error, e.g., show a different notification or provide instructions
        });
    } else {
      // Fallback for browsers that do not support navigator.clipboard
      const textarea = document.createElement("textarea");
      textarea.value = address;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);

      setIsCopied(true);

      // Reset the copied status after a short delay
      setTimeout(() => {
        setIsCopied(false);
      }, 1500);

      // Show toast notification
      toast.success("Copied to clipboard!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1500,
      });
    }
  };


  return (
    <div className="App bg-black min-h-screen">
      <NavBar />
      <div className="page-container mx-4 md:mx-20 flex flex-col items-center justify-center">
        <div className="flex gap-2 md:gap-10 items-center my-10 mx-2">
          <img className="w-16 h-16 md:w-32 md:h-32" src={logo} alt="BTCETF" />
          <h1 className="text-3xl md:text-5xl font-extrabold bg-gradient-to-r from-yellow-500 to-yellow-700 inline-block text-transparent bg-clip-text">
            $BTCETF
          </h1>
          <img className="w-16 h-16 md:w-32 md:h-32" src={logo} alt="BTCETF" />
        </div>
        <div className="header flex flex-col md:flex-row items-center justify-center mx-4 lg:mx-20 my-4 md:my-8">
          <p className="text-white md:leading-15 text-md md:text-3xl text-center md:text-left mb-4 md:mr-4">
            Introducing BTCETF Token (BTCETF) – an AVAX token celebrating
            Bitcoin ETF approvals. Stake in our verified smart contract for
            dynamic passive rewards. Join the Bitcoin community, and let's soar
            to the moon together as we showcase the power of crypto! 🚀 #BTCETF
            #BitcoinETFApproval
          </p>
          <div className="links-pill flex items-center justify-center md:bg-blue-950 md:w-96 md:h-20 md:p-10 rounded-full mb-4 md:mb-0 lg:mx-12">
            <a
              href="https://twitter.com/bitcoinetf_t"
              target="_blank"
              rel="noopener noreferrer"
              className="m-2 md:m-4"
            >
              <BsTwitterX size={30} className="text-white" />
            </a>
            <a
              href="https://t.me/BitcoinETF_Community"
              target="_blank"
              rel="noopener noreferrer"
              className="m-2 md:m-4"
            >
              <FaTelegramPlane size={30} className="text-white" />
            </a>
          </div>
        </div>
        <div className="bg-yellow-500 text-white w-full lg:w-3/5 p-2 md:p-4 rounded-full flex flex-col md:flex-row items-center justify-center gap-2">
          <a
            href="https://snowtrace.io/address/0x2399EC8c5E34f83F24c43f8b0a02616fF0ad5525"
            target="_blank"
            rel="noopener noreferrer"
            className="md:mb-0 text-center text-xs md:text-lg sm:text-base overflow-auto whitespace-nowrap"
          >
            CA: <span className="font-normal md:font-bold md:mx-8">0x2399EC8c5E34f83F24c43f8b0a02616fF0ad5525</span>
          </a>
          <button
            className="cursor-pointer focus:outline mb-2 md:mb-0 hidden md:block"
            onClick={handleCopyToClipboard}
            title="Copy to clipboard"
          >
            <TbCopy size={22} style={{ color: "black", fontWeight: "800" }} />
          </button>
        </div>
        <button
          className="cursor-pointer focus:outline mt-2 block md:hidden "
          onClick={handleCopyToClipboard}
          title="Copy to clipboard"
        >
          <TbCopy size={22} style={{ color: "white", fontWeight: "800" }} />
        </button>

        <Tokenomics />
        <Roadmap />
        <HowToBuy />
        <ToastContainer />
      </div>
      <div className="mx-4 md:mx-10">
        <News />
      </div>
      <div className="mx-4 md:mx-20">
        <WhatIsToken />
      </div>
      <div className="">
        <FAQ />
      </div>
    </div>
  );
}

export default App;
